<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-row
              align-h="between"
              align-v="center"
              class="px-1 pt-2 pb-1"
          >
            <b-col cols="auto">
              <h3 class="m-0">
                Obras
              </h3>
            </b-col>
            <b-col cols="auto">
              <div class="d-flex align-items-center">
                <div v-if="currentUser.roles[0].name === 'super_admin'">
                  <b-link :to="{ name: 'createProject' }">
                    <b-button variant="primary" size="sm" class="mr-1 text-nowrap">
                      {{ "+ Añadir obra" }}
                    </b-button>
                  </b-link>
                </div>
              </div>
            </b-col>
          </b-row>
          <!-- header -->
          <hr class="m-0" />
          <!-- filters -->
          <div class="p-1">
            <div class="col-12">
              <b-row>
                <b-col class="col-11">

                </b-col>
                <b-col class="col-1 text-right">
                  <span class="cursor-pointer" @click="visibleFilter = !visibleFilter">
                    <feather-icon icon="FilterIcon" size="20" />
                  </span>
                </b-col>
              </b-row>
            </div>
            <b-collapse id="collapse" v-model="visibleFilter" class="mt-2">
              <div class="px-2">
                <b-row>
                  <b-col sm="4">
                    <b-form-group label-for="filter-client">
                      <SelectClients v-model="client" />
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <v-select v-model="province" label="name" :options="selectProvinces" :filterable="true"
                      :searchable="true" placeholder="Filtrar por provincia española" />
                  </b-col>
                  <b-col cols="4" class="d-flex justify-content-end align-items-start">
                    <b-form-input v-model="searchTerm" :placeholder="$t('Busqueda')" type="text" />
                    <b-button class="ml-1" @click="handleSearch" variant="primary">
                      {{ $t("Buscar") }}
                    </b-button>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group label-for="filter-status">
                      <v-select v-model="status" label="name" :filterable="true" :searchable="true"
                        :options="selectStatus" :placeholder="'Filtrar por estado'" />
                    </b-form-group>
                  </b-col>
                  <!-- <b-col sm="4">
                    <b-form-group label-for="filter-incidence">
                      <v-select v-model="incidence" label="name" :filterable="true" :searchable="true"
                        :options="selectIncidenceStatus" :placeholder="'Filtrar por incidencias'" />
                    </b-form-group>
                  </b-col> -->
                </b-row>
              </div>
            </b-collapse>
          </div>
          <b-table hover responsive :items="items" :fields="columns" :no-local-sorting="true" :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc" class="table-white">
            <template #cell(name)="data">
              <b-link :to="{ name: 'viewProject', params: { id: data.item.id } }" class="link">
                <p class="m-0">{{ data.item.name }}</p>
                <span v-if="data.item.SKU" class="font-small">{{
                  data.item.SKU
                }}</span>
              </b-link>
            </template>
            <template #cell(client)="data">
              <span v-if="data.item.client">
                <b-link :to="{
                  name: 'viewClient',
                  params: { id: data.item.client.id },
                }" class="link">
                  {{ data.item.client.name }}
                </b-link>
              </span>
            </template>
            <template #cell(responsible)="data">
              <b-link v-if="data.item.responsible" :to="{ name: 'viewUser', params: { id: data.item.id } }" class="link">
                {{ data.item.responsible.name }} {{ data.item.responsible.surname }}
              </b-link>
            </template>
            <template #cell(status)="data">
              <div class="d-flex">
                <b-badge :variant="statusAssetVariants[data.item.status.alias]">
                  <feather-icon :icon="statusVariantIcon(data.item.status.alias)" size="20" style="margin-right: 5px;" />
                  {{ data.item.status.name }}
                </b-badge>

                <b-badge v-if="(data.item.inicidence == 1)" variant="light-warning" style="margin-left: 5px;">
                  <feather-icon icon="AlertTriangleIcon" size="22" />
                </b-badge>

              </div>
            </template>

            <template #cell(actions)="data">
              <span>
                <b-link :to="{ name: 'viewProject', params: { id: data.item.id } }"
                  class="d-inline-block px-50 text-dark">
                  <feather-icon icon="EyeIcon" />
                </b-link>
                <b-link v-if="currentUser.roles[0].name === 'super_admin'"
                  :to="{ name: 'editProject', params: { id: data.item.id } }" class="d-inline-block px-50 text-dark">
                  <feather-icon icon="Edit2Icon" />
                </b-link>
                <span v-if="data.item.canBeDeleted && currentUser.roles[0].name === 'super_admin'"
                  class="d-inline-block px-50 text-danger" @click="deleteProject(data.item.id, data.item.name)">
                  <feather-icon icon="TrashIcon" />
                </span>
              </span>
            </template>
          </b-table>
          <div v-if="totalItems > pageLength" class="card-footer list-footer d-flex align-items-center justify-content-between mb-1 px-2">
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap"> {{ "Por página" }}: </span>
              <b-form-select v-model="pageLength" :options="pages" class="ml-50 mr-1" @input="handlePageChange" />
              <span class="text-nowrap"> {{ "Total" }}: {{ totalItems }}</span>
            </div>
            <div>
              <b-pagination v-model="currentPage" :total-rows="totalItems" :per-page="pageLength" first-number last-number
                align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @change="handleChangePage">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BBadge,
  BPagination,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
  BCard,
  BTable,
  BCol,
  BRow,
  BLink,
  BCollapse,
  BFormGroup,
} from "bootstrap-vue";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import store from "@/store/index";
import { config } from "@/shared/app.config";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { FlatPickrToTimestamp } from "@/libs/helpers";
import { AlertTriangleIcon } from "vue-feather-icons";
import ButtonOrders from "@/views/steps/ButtonOrders.vue";
import SelectClients from "@/components/clients/select/ClientsSelect.vue";

export default {
  components: {
    SelectClients,
    ButtonOrders,
    BCard,
    BTable,
    BButton,
    BPagination,
    BBadge,
    BCol,
    BRow,
    BLink,
    BFormInput,
    BFormSelect,
    BCollapse,
    config,
    vSelect,
    BFormGroup,
  },
  data() {
    return {
      visibleFilter: false,
      // statusProjectsText: config.statusProjectsText,
      statusAssetVariants: config.statusAssetVariants,

      client: "",
      status: "",
      log: [],
      sortBy: "",
      sortDesc: "",
      currentPage: 1,
      pageLength: 10,
      dir: false,
      pages: ["10", "15", "25"],
      columns: [
        {
          label: "Referencia",
          key: "ref",
        },
        {
          label: "Nombre",
          key: "name",
          sortable: true,
        },
        {
          label: "Cliente",
          key: "client",
        },
        {
          label: "Responsable de mantenimiento",
          key: "responsible",
        },
        {
          label: "Estado",
          key: "status",
        },
        {
          label: "Provincia",
          key: "province",
        },
        {
          label: "Acciones",
          key: "actions",
          class: "column-actions",
        },
      ],
      searchTerm: "",
      province: '',
      incidence: '',
      selectIncidenceStatus: [
        {
          value: 1, name: 'Activo',
        },
        {
          value: 0, name: 'Inactivo'
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      currentClient: "clients/getCurrentClient",
      items: "projects/getItems",
      totalItems: "projects/getTotalItems",
      currentUser: 'auth/getUser',
      selectProvinces: 'provinces/getSelectProvinces',

      selectStatus: 'maintenances/getSelectStatus',
    }),
    statusVariantIcon() {
      const statusIcon = {
        1: 'CheckCircleIcon',
        0: 'XCircleIcon',
        active: 'CheckCircleIcon',
        inactive: 'XCircleIcon'
      }

      return status => statusIcon[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  watch: {
    incidence() {
      this.currentPage = 1;
      this.chargeData();
    },
    sortBy() {
      this.currentPage = 1;
      this.chargeData();
    },
    sortDesc() {
      this.currentPage = 1;
      this.chargeData();
    },
    user() {
      this.currentPage = 1;
      this.chargeData();
    },
    client() {
      this.currentPage = 1;
      this.chargeData();
    },
    project() {
      this.currentPage = 1;
      this.chargeData();
    },
    orderby() {
      this.currentPage = 1;
      this.chargeData();
    },
    province() {
      this.currentPage = 1;
      this.chargeData();
    },
    status() {
      this.currentPage = 1;
      this.chargeData();
    },
  },
  async created() {
    await this.chargeData();
    // this.getSelectStatus();

    await this.getSelectStatus({
      type: "project"
    })

    await this.getSelectProvinces({ page: 1, per_page: 9999, search: '', orderBy: '' })

  },
  methods: {
    ...mapActions({
      list: "projects/getListProjects",
      delete: "projects/delete",
      getSelectProvinces: 'provinces/selectProvinces',
      getSelectStatus: 'maintenances/getSelectStatus',
    }),
    chargeData() {
      let orderBy = "";

      if (this.sortBy) {
        const type = this.sortDesc ? "desc" : "asc";
        orderBy = {
          column: this.sortBy,
          type,
        };
      }

      let client = this.client ? this.client.id : "";
      let incidence = this.incidence ? Boolean(this.incidence.value) : "";
      let province = this.province ? this.province.id : "";

      console.log('listado', incidence)

      this.list({
        client,
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        orderBy,
        status: this.status.id,
        province_id: province,
        incidence,
      });
    },
    handleSearch() {
      this.currentPage = 1;
      this.chargeData();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.chargeData();
    },
    handlePageChange() {
      this.currentPage = 1;
      this.chargeData();
    },
    onSortChange(params) {
      this.currentPage = 1;
      this.chargeData();
    },
    deleteProject(id, name) {
      this.$bvModal
        .msgBoxConfirm(`¿Estás seguro de eliminar ${name}?`, {
          bodyClass: "bodyDelete",
          footerClass: "footerDelete",
          okVariant: "danger",
          okTitle: "Eliminar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-danger",
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.delete(id);
          }
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
